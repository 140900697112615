import React from 'react';


const MySlider = () => {

  return (
    <section class="parallax-bg" data-stellar-background-ratio="0.5">
  
        <div class="js-height-full container">
            <div class="intro-content white-color text-center vertical-section">
                <div class="vertical-content">
                <h4 class="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.8s">Welcome to Avtar Constructions</h4>
                <div><h1 class="wow zoomIn m-bottom-20" data-wow-duration="1s" data-wow-delay="0.6s">Construction Company</h1></div>
                <div><button data-scroll href="#portfolio" class="btn btn-main btn-theme wow fadeInUp" data-wow-delay="0.8s" formsappId="6593e5f3f803fb825089b1c7"></button></div>
                </div>
            </div>
        </div>
      
    </section>

  );
};

export default MySlider;
