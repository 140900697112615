import React from 'react';

const VideoSettings = {
  videoURL: 'https://youtu.be/dwfjayxRvqw',
  containment: '.video-bg',
  autoPlay: true,
  mute: true,
  loop: true,
  showControls: false,
  startAt: 0,
  opacity: 1,
};

const VideoSection = ({ backgroundImage, captionContent }) => {
  return (
    <section className="video-bg overlay-dark" style={{ backgroundImage: `url('${backgroundImage}')` }}>
      <div className="js-height-full container">
        <div className="video-player" data-property={JSON.stringify(VideoSettings)}></div>

        <div className="vertical-section">
          <div className="vertical-content">
            <VideoCaption content={captionContent} />
          </div>
        </div>
      </div>
    </section>
  );
};

const VideoCaption = ({ content }) => {
  return (
    <div className="video-caption text-center white-color">
      <h2 className="p-top-20">Watch Our Video</h2>
      <div className="divider-center-small divider-white"></div>
      <h6 className="p-bottom-20">{content}</h6>
    </div>
  );
};

export default VideoSection;
