import React from 'react';

const ClientsSection = () => {
  return (
    <section id="client" className="light-bg p-top-80 p-bottom-80">

      {/* Section Title */}
      <div className="section-title text-center m-bottom-40">
        <h2>Our Clients</h2>
        <div className="divider-center-small"></div>
      </div>

      {/* Clients */}
      <div className="container">
        <div className="row">

          <div id="owl-clients" className="owl-carousel owl-theme client text-center">

            {/* Client 1 */}
            <div className="client-item text-center">
              <img className="img-responsive" src="img/client/1.png" alt="" />
            </div>

            {/* Client 2 */}
            <div className="client-item text-center">
              <img className="img-responsive" src="img/client/2.png" alt="" />
            </div>

            {/* Client 3 */}
            <div className="client-item text-center">
              <img className="img-responsive" src="img/client/3.png" alt="" />
            </div>

            {/* Client 4 */}
            <div className="client-item text-center">
              <img className="img-responsive" src="img/client/4.png" alt="" />
            </div>

            {/* Client 5 */}
            <div className="client-item text-center">
              <img className="img-responsive" src="img/client/5.png" alt="" />
            </div>

            {/* Client 6 */}
            <div className="client-item text-center">
              <img className="img-responsive" src="img/client/6.png" alt="" />
            </div>

            {/* Client 7 */}
            <div className="client-item text-center">
              <img className="img-responsive" src="img/client/7.png" alt="" />
            </div>

          </div> {/* /#owl-clients */}

        </div> {/* /.row */}
      </div> {/* /.container */}

    </section>
  );
};

export default ClientsSection;
