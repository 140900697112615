import React from 'react';

const TeamSection = () => {
  const teamMembers = [
    { id: 1, name: 'Stephen Joe', position: 'Director', image: 'img/team/1.jpg' },
    { id: 2, name: 'Laura Doe', position: 'Creative Director', image: 'img/team/2.jpg' },
    { id: 3, name: 'Andy Doe', position: 'Marketing', image: 'img/team/3.jpg' },
    { id: 4, name: 'Inani Joe', position: 'Sales', image: 'img/team/4.jpg' },
  ];

  return (
    <section id="team" className="p-top-80 p-bottom-50">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="section-title text-center m-bottom-40">
              <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.6s">Team Members</h2>
              <div className="divider-center-small wow zoomIn" data-wow-duration="1s" data-wow-delay="0.6s"></div>
              <p className="section-subtitle wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                <em>Resolution possession discovered surrounded advantages has but few add. Yet walls times spoil put.
                  Be it reserved contempt rendered smallest. Studied to passage it mention calling believe an.</em>
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          {teamMembers.map(member => (
            <div key={member.id} className="col-md-3 col-sm-6 col-xs-6 p-bottom-30">
              <TeamMember name={member.name} position={member.position} image={member.image} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const TeamMember = ({ name, position, image }) => {
  return (
    <div className="team-item wow zoomIn" data-wow-duration="1s" data-wow-delay="0.9s">
      <div className="team-item-image">
        <div className="team-item-image-overlay">
          <div className="team-item-icons">
            <a href="#"><i className="fa fa-twitter"></i></a>
            <a href="#"><i className="fa fa-facebook"></i></a>
            <a href="#"><i className="fa fa-google-plus"></i></a>
          </div>
        </div>
        <img src={image} alt={name} />
      </div>
      <div className="team-item-info">
        <div className="team-item-name">{name}</div>
        <div className="team-item-position">{position}</div>
      </div>
    </div>
  );
};

export default TeamSection;
