import React from 'react';

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="container">
        <small className="copyright pull-left">
          Copyrights © 2019 All Rights Reserved By <a href="/">tabthemes</a>.
        </small>
        <div className="social-icon pull-right">
          <a href="#"><i className="fa fa-facebook"></i></a>
          <a href="#"><i className="fa fa-twitter"></i></a>
          <a href="#"><i className="fa fa-pinterest"></i></a>
          <a href="#"><i className="fa fa-google"></i></a>
          <a href="#"><i className="fa fa-rss"></i></a>
          <a href="#"><i className="fa fa-globe"></i></a>
        </div>
        {/* /social-icon */}
      </div>
      {/* /container */}
    </footer>
  );
};

export default Footer;
