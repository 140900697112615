import React from 'react';

const ContactSection = () => {
  return (
    <section id="contact" className="p-top-80 p-bottom-50">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="section-title text-center m-bottom-40">
              <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.6s">Contact</h2>
              <div className="divider-center-small wow zoomIn" data-wow-duration="1s" data-wow-delay="0.6s"></div>
              <p className="section-subtitle wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                <em>Lose away off why half led have near bed. At engage simple father of period others except. My giving do summer time dance hero of though narrow marked at.</em>
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-7 col-sm-7 p-bottom-30">
            <div className="contact-form row">
              <form name="ajax-form" id="ajax-form" action="contact.php" method="post">
                <div className="col-sm-6 contact-form-item wow zoomIn">
                  <input name="name" id="name" type="text" placeholder="Your Name: *" />
                  <span className="error" id="err-name">please enter name</span>
                </div>
                <div className="col-sm-6 contact-form-item wow zoomIn">
                  <input name="email" id="email" type="text" placeholder="E-Mail: *" />
                  <span className="error" id="err-email">please enter e-mail</span>
                  <span className="error" id="err-emailvld">e-mail is not a valid format</span>
                </div>
                <div className="col-sm-12 contact-form-item wow zoomIn">
                  <textarea name="message" id="message" placeholder="Your Message"></textarea>
                </div>
                <div className="col-sm-12 contact-form-item">
                  <button className="send_message btn btn-main btn-theme wow fadeInUp" id="send" data-lang="en">submit</button>
                </div>
                <div className="clear"></div>
                <div className="error text-align-center" id="err-form">There was a problem validating the form please check!</div>
                <div className="error text-align-center" id="err-timedout">The connection to the server timed out!</div>
                <div className="error" id="err-state"></div>
              </form>
              <div className="clearfix"></div>
              <div id="ajaxsuccess">Successfully sent!!</div>
              <div className="clear"></div>
            </div>
          </div>

          <div className="col-md-5 col-sm-5 p-bottom-30">
            <address className="contact-info">
              <p className="m-bottom-30 wow slideInRight">Spring formal no county ye waited. My whether cheered at regular it of promise blushes perhaps.</p>

              <div className="m-top-20 wow slideInRight">
                <div className="contact-info-icon">
                  <i className="fa fa-location-arrow"></i>
                </div>
                <div className="contact-info-title">
                  Address:
                </div>
                <div className="contact-info-text">
                  149 Null Street, New York, NY 098
                </div>
              </div>

              <div className="m-top-20 wow slideInRight">
                <div className="contact-info-icon">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="contact-info-title">
                  Phone number:
                </div>
                <div className="contact-info-text">
                  +1-000-1111-3333
                </div>
              </div>

              <div className="m-top-20 wow slideInRight">
                <div className="contact-info-icon">
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="contact-info-title">
                  Email:
                </div>
                <div className="contact-info-text">
                  support@tabthemes.com
                </div>
              </div>
            </address>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
