import React from 'react';

const SkillsAndWhyChooseUsSection = () => {
  return (
    <section className="light-bg p-top-80 p-bottom-40">
      <div className="container">
        <div className="row">

          <div className="col-md-6 p-bottom-40">
            {/* Section Title */}
            <div className="m-bottom-30">
              <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.6s">Our Skills</h2>
              <div className="divider-small wow zoomIn" data-wow-duration="1s" data-wow-delay="0.6s"></div>
            </div>

            {/* === Progress item 1 === */}
            <div className="progress-heading clearfix wow fadeIn">
              <h6 className="pull-left">Coding</h6>
              <p className="pull-right">80%</p>
            </div>
            <div className="progress progress-thin">
              <div className="progress-bar wow zoomIn" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: '80%' }} data-wow-delay="0.3s"></div>
            </div>

            {/* === Progress item 2 === */}
            <div className="progress-heading clearfix wow fadeIn">
              <h6 className="pull-left">Design</h6>
              <p className="pull-right">90%</p>
            </div>
            <div className="progress progress-thin">
              <div className="progress-bar wow zoomIn" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style={{ width: '90%' }} data-wow-delay="0.3s"></div>
            </div>

            {/* === Progress item 3 === */}
            <div className="progress-heading clearfix wow fadeIn">
              <h6 className="pull-left">Branding</h6>
              <p className="pull-right">60%</p>
            </div>
            <div className="progress progress-thin">
              <div className="progress-bar wow zoomIn" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: '60%' }} data-wow-delay="0.3s"></div>
            </div>

            {/* === Progress item 4 === */}
            <div className="progress-heading clearfix wow fadeIn">
              <h6 className="pull-left">Marketing</h6>
              <p className="pull-right">73%</p>
            </div>
            <div className="progress progress-thin">
              <div className="progress-bar wow zoomIn" role="progressbar" aria-valuenow="73" aria-valuemin="0" aria-valuemax="100" style={{ width: '73%' }} data-wow-delay="0.3s"></div>
            </div>

            {/* === Progress item 5 === */}
            <div className="progress-heading clearfix wow fadeIn">
              <h6 className="pull-left">Consultancy</h6>
              <p className="pull-right">75%</p>
            </div>
            <div className="progress progress-thin">
              <div className="progress-bar wow zoomIn" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }} data-wow-delay="0.3s"></div>
            </div>

          </div> {/* /.col */}

          <div className="col-md-6 p-bottom-40">

            {/* Section Title Right */}
            <div className="m-bottom-30">
              <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.6s">Why Choose Us</h2>
              <div className="divider-small wow zoomIn" data-wow-duration="1s" data-wow-delay="0.6s"></div>
            </div>

            {/* === Accordion === */}
            <div id="accordion" className="panel-group wow fadeIn" data-wow-delay="0.6s">

              {/* === Accordion item 1 === */}
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne">Necessary ye contented newspaper zealously</a>
                  </h4>
                </div>
                <div id="collapseOne" className="panel-collapse collapse in">
                  <div className="panel-body">
                    <p>Fat new smallness few supposing suspicion two. Course sir people worthy horses add entire suffer. How one dull get busy dare far. At principle perfectly by sweetness do.</p>
                  </div>
                </div>
              </div>

              {/* === Accordion item 2 === */}
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">Add you viewing ten equally believe put</a>
                  </h4>
                </div>
                <div id="collapseTwo" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>Connection too unaffected expression led son possession. New smiling friends and her another. Leaf she does none love high yet. Snug love will up bore as be.</p>
                  </div>
                </div>
              </div>

              {/* === Accordion item 3 === */}
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4 className="panel-title">
                    <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">Believing neglected so allowance</a>
                  </h4>
                </div>
                <div id="collapseThree" className="panel-collapse collapse">
                  <div className="panel-body">
                    <p>Do am he horrible distance marriage so although. Afraid assure square so happen mr an before. His many same been well can high that. Forfeited did law eagerness allowance improving assurance bed.</p>
                  </div>
                </div>
              </div>

            </div> {/* /.panel-group */}

          </div> {/* /.col */}

        </div> {/* /.row */}
      </div> {/* /.container */}
    </section>
  );
};

export default SkillsAndWhyChooseUsSection;
