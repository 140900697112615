import React from 'react';
import img0 from '../img/blog/b1.jpg';

const BlogSection = () => {
  const blogPosts = [
    {
      id: 1,
      date: '12 January 2017',
      comments: '4 Comments',
      title: 'Working in Cool Head',
      image: {img0},
      content: 'Impossible alteration devonshire to is interested stimulated dissimilar. To matter esteem polite do if. Those an equal point no years do. Depend warmth fat but her but played.',
    },
    {
      id: 2,
      date: '06 January 2017',
      comments: '2 Comments',
      title: 'Sing me to sleep',
      image: 'img/blog/b2.jpg',
      content: 'Impossible alteration devonshire to is interested stimulated dissimilar. To matter esteem polite do if. Those an equal point no years do. Depend warmth fat but her but played.',
    },
    {
      id: 3,
      date: '02 January 2017',
      comments: '4 Comments',
      title: 'Hold Me Tight',
      image: 'img/blog/b3.jpg',
      content: 'Impossible alteration devonshire to is interested stimulated dissimilar. To matter esteem polite do if. Those an equal point no years do. Depend warmth fat but her but played.',
    },
    {
      id: 4,
      date: '01 January 2017',
      comments: '7 Comments',
      title: 'Only I can do it',
      image: 'img/blog/b4.jpg',
      content: 'Impossible alteration devonshire to is interested stimulated dissimilar. To matter esteem polite do if. Those an equal point no years do. Depend warmth fat but her but played.',
    },
  ];

  return (
    <section id="blog" className="p-top-80 p-bottom-80">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="section-title text-center m-bottom-40">
              <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.6s">Blog Posts</h2>
              <div className="divider-center-small wow zoomIn" data-wow-duration="1s" data-wow-delay="0.6s"></div>
              <p className="section-subtitle wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                <em>Bed sincerity yet therefore forfeited his certainty neglected questions. Pursuit chamber as elderly amongst on. Distant however warrant farther to of.</em>
              </p>
            </div>
          </div>
        </div>

        <div id="owl-blog" className="owl-carousel owl-theme">
          {blogPosts.map((post) => (
            <BlogItem key={post.id} post={post} />
          ))}
        </div>
      </div>
    </section>
  );
};

const BlogItem = ({ post }) => {
  return (
    <div className="blog wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.7s">
      <div className="blog-media">
        <a href="blog_single_post.html"><img src={post.image} alt="" /></a>
      </div>
      <div className="blog-post-info clearfix">
        <span className="time"><i className="fa fa-calendar"></i> {post.date}</span>
        <span className="comments"><a href="#"><i className="fa fa-comments"></i> {post.comments}</a></span>
      </div>
      <div className="blog-post-body">
        <h4><a className="title" href="blog_single_post.html">{post.title}</a></h4>
        <p className="p-bottom-20">{post.content}</p>
        <a href="blog_single_post.html" className="read-more">Read More </a>
      </div>
    </div>
  );
};

export default BlogSection;
