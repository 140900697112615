import React from 'react';
import logo from '../img/gas-safe.svg';

const NavigationBar = () => {
  return (
    <header className="nav-solid" id="home">
      <nav className="navbar navbar-fixed-top">
        <div className="navigation">
          <div className="container-fluid">
            <div className="row">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-collapse"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>

                {/* Logo */}
                <div className="logo-container">
                  <div className="logo-wrap local-scroll">
                    <a href="#home">
                      <img
                        className="logo"
                        src={logo}
                        height={50}
                        alt="logo"
                        data-rjs="2"
                      />
                    </a>
                    
                  </div>
                </div>
              </div>

              <div className="col-md-8 col-xs-12 nav-wrap">
                <div
                  className="collapse navbar-collapse"
                  id="navbar-collapse"
                >
                  <ul className="nav navbar-nav navbar-right">
                    <li>
                      <a data-scroll href="#home">
                        Home
                      </a>
                    </li>
                    <li>
                      <a data-scroll href="#service">
                        Service
                      </a>
                    </li>
                    <li>
                      <a data-scroll href="#portfolio">
                        Portfolio
                      </a>
                    </li>
                    <li>
                      <a data-scroll href="#team">
                        Team
                      </a>
                    </li>
                    <li>
                      <a data-scroll href="#testimonials">
                        Testimonials
                      </a>
                    </li>
                    <li>
                      <a data-scroll href="#blog">
                        Blog
                      </a>
                    </li>
                    <li>
                      <a data-scroll href="#contact">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavigationBar;
