import React from 'react';

const BusinessGrowthSection = () => {
  return (
    <section className="p-top-80 p-bottom-80">
      <div className="container">
        <div className="row">

          <div className="col-md-6">
            {/* Section Title */}
            <div className="m-bottom-30">
              <h2 className="wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.6s">We Help Grow Your Business</h2>
              <div className="divider-small wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.7s"></div>
            </div>

            <div className="wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.8s">
              <p>Calling observe for who pressed raising his. Can connection instrument astonished unaffected his motionless preference. Announcing say boy precaution unaffected. Do to be agreeable conveying oh assurance. Wicket longer admire do barton vanity itself do in it. Preferred to sportsmen it engrossed listening. Park gate sell they west hard for the. </p>
              <p>Up colonel so between removed so do. Years use place decay sex worth drift age. Men lasting out end article express fortune demands own charmed. About are money ask how seven.</p>
            </div>

            <a data-scroll href="#contact" className="m-top-30 m-bottom-30 btn btn-main wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.8s">Contact Us</a>
          </div> {/* /.col */}

          <div className="col-md-6">
            <div className="feature-image">
              <img src="img/sample1.png" alt="Feature Image" className="img-responsive wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.6s" />
            </div>
          </div> {/* /.col */}

        </div> {/* /.row */}
      </div> {/* /.container */}
    </section>
  );
};

export default BusinessGrowthSection;
