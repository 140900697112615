import React from 'react';

const TestimonialsSection = () => {
  return (
    <section id="testimonials" className="parallax-bg overlay-dark p-top-80 p-bottom-80" style={{ backgroundImage: 'url(img/testimonial-bg.jpg)' }} data-stellar-background-ratio="0.5">

      {/* Section Title */}
      <div className="section-title text-center white-color m-bottom-40">
        <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.6s">Testimonials</h2>
        <div className="divider-center-small divider-white wow zoomIn" data-wow-duration="1s" data-wow-delay="0.6s"></div>
      </div>

      {/* Testimonials */}
      <div id="owl-testimonials" className="owl-carousel owl-theme testimonial text-center white-color">

        {/* Testimonial item 1 */}
        <div className="testimonial-item text-center">
          <p className="testimonial-desc">Residence you satisfied and rapturous certainty two. Procured outweigh as outlived so so. On in bringing graceful proposal blessing of marriage outlived. Son rent face our loud near.</p>
          <div className="testimonial-thumb">
            <img className="img-responsive" src="img/testimonial/1.jpg" alt="" />
          </div>
          <h5 className="testimonial-author">Jenny Doe - Star Inc</h5>
        </div>

        {/* Testimonial item 2 */}
        <div className="testimonial-item text-center">
          <p className="testimonial-desc">Excellent so to no sincerity smallness. Removal request delight if on he we. Unaffected in we by apartments astonished to decisively themselves. Offended ten old consider speaking.</p>
          <div className="testimonial-thumb">
            <img className="img-responsive" src="img/testimonial/2.jpg" alt="" />
          </div>
          <h5 className="testimonial-author">Kathy Doe - Service Corp</h5>
        </div>

        {/* Testimonial item 3 */}
        <div className="testimonial-item text-center">
          <p className="testimonial-desc">Advanced and procured civility not absolute put continue. Overcame breeding or my concerns removing desirous so absolute. My melancholy unpleasing imprudence considered in advantages.</p>
          <div className="testimonial-thumb">
            <img className="img-responsive" src="img/testimonial/3.jpg" alt="" />
          </div>
          <h5 className="testimonial-author">Jack Doe - Inka Design</h5>
        </div>

      </div> {/* /#owl-testimonials */}

    </section>
  );
};

export default TestimonialsSection;
