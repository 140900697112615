import React from 'react';

const StatSection = () => {
  const stats = [
    { icon: 'fa fa-briefcase', number: 177, text: 'Projects Done' },
    { icon: 'fa fa-users', number: 123, text: 'Happy Clients' },
    { icon: 'fa fa-thumbs-up', number: 999, text: 'Likes Gained' },
    { icon: 'fa fa-trophy', number: 101, text: 'Awards Received' },
  ];

  return (
    <section id="stat" className="p-top-80 p-bottom-40">
      <div className="container">
        <div className="row">
          {stats.map((stat, index) => (
            <div key={index} className="col-md-3 col-sm-6 col-xs-6 p-bottom-40">
              <StatItem icon={stat.icon} number={stat.number} text={stat.text} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const StatItem = ({ icon, number, text }) => {
  return (
    <div className="stat-item wow zoomIn" data-wow-duration="1s" data-wow-delay="0.3s">
      <div className="stat-item-icon">
        <i className={icon}></i>
      </div>
      <div className="stat-item-number">
        {number}
      </div>
      <div className="stat-item-text" dangerouslySetInnerHTML={{ __html: text.replace('<br>', '<br/>') }} />
    </div>
  );
};

export default StatSection;
