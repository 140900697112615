import React from 'react'
import NavigationBar from '../components/NavigationBar'
import SliderSection from '../components/SliderSection'
import ServiceSection from '../components/ServiceSection'
import PortfolioItems from '../components/PortfolioItems'
import BusinessGrowthSection from '../components/BusinessGrowthSection'
import VideoSection from '../components/VideoSection'
import TeamSection from '../components/TeamSection'
import SkillsAndWhyChooseUsSection from '../components/SkillsAndWhyChooseUsSection'
import StatSection from '../components/StatSection'
import TestimonialsSection from '../components/TestimonialsSection'
import BlogSection from '../components/BlogSection'
import ClientsSection from '../components/ClientsSection'
import ContactSection from '../components/ContactSection'
import Footer from '../components/Footer'

function HomePage() {
  return (
    <div>
      <NavigationBar />
      <SliderSection />
      <ServiceSection />
      <PortfolioItems />
      <BusinessGrowthSection />
      <VideoSection />
      <TeamSection />
      <SkillsAndWhyChooseUsSection />
      <StatSection />
      <TestimonialsSection />
      <BlogSection />
      <ClientsSection />
      <ContactSection />
      <Footer />
    </div>
  )
}

export default HomePage