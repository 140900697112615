import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';

const PortfolioItems = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('*');

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleFilter = (filterValue) => setFilter(filterValue);

  const portfolioItems = [
    { category: 'branding', type: 'image', source: 'img/portfolio/1.jpg', title: 'Project Name', description: 'Branding' },
    // Add more items following the same structure
  ];

  const filteredItems = filter === '*' ? portfolioItems : portfolioItems.filter(item => item.category === filter);

  return (
    <section id="portfolio" className="light-bg p-top-80 p-bottom-80">
      <div className="container">
        {/* Section Title */}
        <div className="section-title text-center m-bottom-30">
          <h2><strong>Portfolio</strong></h2>
          <div className="divider-center-small"></div>
        </div>

        {/* Portfolio-filter */}
        <ul className="pf-filter text-center list-inline">
          <li><a href="#" onClick={() => handleFilter('*')} className={filter === '*' ? 'iso-active iso-button' : 'iso-button'}>All</a></li>
          <li><a href="#" onClick={() => handleFilter('branding')} className={filter === 'branding' ? 'iso-active iso-button' : 'iso-button'}>Branding</a></li>
          {/* Add more filter buttons for different categories */}
        </ul>

        {/* Portfolio */}
        <div className="portfolio portfolio-isotope col-3 gutter">
          {filteredItems.map((item, index) => (
            <div className={`pf-item ${item.category}`} key={index}>
              {item.type === 'image' ? (
                <a href={item.source} className="pf-style lightbox-image mfp-image">
                  <div className="pf-image">
                    <img src={item.source} alt="" />
                    <div className="overlay">
                      <div className="overlay-caption">
                        <div className="overlay-content">
                          <div className="pf-info white-color">
                            <h4 className="pf-title">{item.title}</h4>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              ) : (
                <div className="pf-item video webdesign" key={index}>
                  <button onClick={openModal}>
                    <div className="pf-image">
                      <img src={item.source} alt="" />
                      <div className="overlay">
                        <div className="overlay-caption">
                          <div className="overlay-content">
                            <div className="pf-info white-color">
                              <h4 className="pf-title">{item.title}</h4>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        
        {/* Video Modal */}
        <ModalVideo channel='youtube' isOpen={isOpen} videoId='6D-A6CL3Pv8' onClose={closeModal} />
      </div>
    </section>
  );
};

export default PortfolioItems;
