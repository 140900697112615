import React from 'react';

const ServiceSection = () => {

  const servicePostsRow1 = [
    {
      id: 1,
      row: 'row1',
      heading: "Gas & electrical engineer",
      data: "We specialize in designing and implementing cutting-edge solutions for energy systems.",
      icon: "fa-solid fa-fire-flame-simple",
    },
    {
      id: 2,
      row: 'row1',
      heading: "Landlord gas certificates",
      data: "We provide Gas Safety Certificates (CP12) to landlords, homeowners, and estate agents within London, and surrounding areas.",
      icon: "fa-solid fa-fire-flame-simple",
    },
    {
      id: 3,
      row: 'row1',
      heading: "Boiler services",
      data: "We specialize in designing and implementing cutting-edge solutions for energy systems.",
      icon: "fa-solid fa-fire-flame-simple",
    },
    {
      id: 4,
      row: 'row1',
      heading: "Gas safety check",
      data: "We specialize in designing and implementing cutting-edge solutions for energy systems.",
      icon: "fa-solid fa-fire-flame-simple",
    },
  ];

  const servicePostsRow2 = [
    { 
      id: 5,
      row: 'row2',
      heading: "Design & build kitchen extensions",
      data: "We specialize in designing and implementing cutting-edge solutions for energy systems.",
      icon: "fa-solid fa-fire-flame-simple",
    },
    {
      id: 6,
      row: 'row2',
      heading: "Single double story houses",
      data: "We specialize in designing and implementing cutting-edge solutions for energy systems.",
      icon: "fa-solid fa-fire-flame-simple",
    },
    {
      id: 7,
      row: 'row2',
      heading: "Upvc windows",
      data: "We provide Gas Safety Certificates (CP12) to landlords, homeowners, and estate agents within London, and surrounding areas.",
      icon: "fa-solid fa-fire-flame-simple",
    },
    {
      id: 8,
      row: 'row2',
      heading: "Decorate",
      data: "We specialize in designing and implementing cutting-edge solutions for energy systems.",
      icon: "fa-solid fa-fire-flame-simple",
    },
  ];

  const servicePostsRow3 = [
    {
      id: 9,
      row: 'row3',
      heading: "Roofing",
      data: "We specialize in designing and implementing cutting-edge solutions for energy systems.",
      icon: "fa-solid fa-fire-flame-simple",
    },
    {
      id: 10,
      row: 'row3',
      heading: "All type of building work",
      data: "We specialize in designing and implementing cutting-edge solutions for energy systems.",
      icon: "fa-solid fa-fire-flame-simple",
    },
  ];

  return (
    <section id="service" className="p-top-80 p-bottom-80">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            {/* Section Title */}
            <div className="section-title text-center m-bottom-40">
              <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.6s"><strong>Service</strong></h2>
              <div className="divider-center-small wow zoomIn" data-wow-duration="1s" data-wow-delay="0.6s"></div>
              <p className="section-subtitle wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.6s">
                <em>Cordially convinced did incommode existence put out suffering certainly.
                  Besides another and saw ferrars limited ten say unknown.
                  So mainly it was not that difficult to understand.
                </em>
              </p>
            </div>
          </div> {/* /.col */}
        </div>

        <div className='row'>
          {servicePostsRow1.map((post) => (
            <Rows key={post.id} post={post} />
          ))}
        </div>
        <div className='row'>
          {servicePostsRow2.map((post1) => (
            <Rows key={post1.id} post={post1} />
          ))}
        </div>
        <div className='row'>
          {servicePostsRow3.map((post2) => (
            <Rows key={post2.id} post={post2} />
          ))}
        </div>
      </div>
    </section>
  );
};

const Rows = ({ post }) => (
  <div className={post.row}>
    <div className="col-md-3 col-sm-6 m-bottom-20 ">
      <div className="service wow zoomIn serviceRow" data-wow-duration="1s" data-wow-delay="0.6s">
        <div className="service-icon">
          <i className={post.icon} aria-hidden="true"></i>
        </div>
        <h4>{post.heading}</h4>
        <div className="service-text">
          <p>{post.data}</p>
        </div>
      </div>
    </div>
  </div>
);

export default ServiceSection;
